import { createContext } from 'react';

import { Store } from './store';

const RedaxContext = createContext<Store<any>>(
  (undefined as unknown) as Store<any>
);

RedaxContext.displayName = 'RedaxContext';

export { RedaxContext };
