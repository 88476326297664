import * as React from 'react';

import { createForm } from '../create-form';
import { FormState } from '../forms/types';
import { connect, PrepareActions } from '../redax';
import { login } from '../store/actions';
import { RequestState, StoreState, User } from '../store/types';

type ActionProps = PrepareActions<
  StoreState,
  {
    login: typeof login;
  }
>;

interface StateProps {
  user: RequestState<User>;
}

type Props = StateProps & ActionProps;

const AdminForm = createForm<FormState, 'adminLogin'>('adminLogin');

const AdminLogin = (props: Props) => {
  const { username, password } = AdminForm.useValues();

  return (
    <main className="admin-login">
      <h1>Admin login</h1>
      <AdminForm.Form onSubmit={props.login}>
        <div className="form-group">
          <label>Username</label>
          <AdminForm.TextField name="username" />
        </div>
        <div className="form-group">
          <label>Password</label>
          <AdminForm.PasswordField name="password" />
        </div>
        {props.user.error && <p className="error">{props.user.error}</p>}
        <button
          className="primary"
          type="submit"
          disabled={!username || !password || props.user.loading}
        >
          Login
        </button>
      </AdminForm.Form>
    </main>
  );
};

export default connect<StoreState, StateProps, ActionProps>(
  utils => ({
    user: utils.select(['requests', 'user']),
  }),
  { login }
)(AdminLogin);
