import * as pathToRegExp from 'path-to-regexp';
import { ReactElement } from 'react';

import { RouteProps } from './route';
import withRouter, { InjectedRouteProps } from './with-router';

interface OwnProps {
  children: ReadonlyArray<ReactElement<RouteProps> | false>;
}

type SwitchProps = InjectedRouteProps & OwnProps;

const Switch = (props: SwitchProps) => {
  return (
    props.children.find(child => {
      if (!child) {
        return false;
      }

      return pathToRegExp.match(child.props.path)(props.location.pathname);
    }) || null
  );
};

export default withRouter(Switch);
