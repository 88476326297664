import { UNKNOWN } from './constants';
import { Selector } from './selector';
import {
  Action,
  DeepReadonly,
  PrepareAction,
  Select,
  SelectorPath,
  Subscriber,
} from './types';

export class Store<S extends Record<string, any>> {
  private state: S;
  private globalSubscribers: Array<Subscriber<S>> = [];
  private keySubscribers: { [P in keyof S]?: Array<Subscriber<S[P]>> } = {};
  private updatedKeys: Array<keyof S> = [];

  public constructor(initialState: S) {
    this.state = initialState;
  }

  public getState() {
    return this.state as DeepReadonly<S>;
  }

  public update<K extends keyof S>(
    key: K,
    transform: (value: DeepReadonly<S[K]>) => DeepReadonly<S[K]>
  ) {
    this.state = {
      ...this.state,
      [key]: transform(this.state[key]),
    };

    this.updatedKeys.push(key);

    this.emit();
  }

  public subscribe(
    keys: ReadonlyArray<never>,
    subscriber: Subscriber<S>
  ): () => void;
  public subscribe(
    keys: ReadonlyArray<keyof S>,
    subscriber: Subscriber<S[keyof S]>
  ): () => void;
  public subscribe(
    keys: ReadonlyArray<keyof S> | ReadonlyArray<never>,
    subscriber: Subscriber<any>
  ) {
    if (!keys.length) {
      if (this.globalSubscribers.indexOf(subscriber) < 0) {
        this.globalSubscribers.push(subscriber);
      }
    } else {
      keys.forEach(key => {
        const subscriberKey = this.keySubscribers[key];

        if (subscriberKey) {
          if (subscriberKey.indexOf(subscriber) < 0) {
            subscriberKey.push(subscriber);
          }
        } else {
          const createdSubscribers = [subscriber];
          this.keySubscribers[key] = createdSubscribers;
        }
      });
    }

    return this.createUnsubscribe(keys, subscriber);
  }

  public prepareAction<A extends Action<S>>(action: A) {
    const preparedAction = action(this) as PrepareAction<S, A>;
    if (!preparedAction.name) {
      Object.defineProperty(preparedAction, 'name', {
        value: `prepareAction(${action.name || UNKNOWN})`,
      });
    }
    return preparedAction;
  }

  public select: Select<S> = function select(
    this: Store<S>,
    path: SelectorPath,
    transformValue?: (value: any) => AnalyserNode
  ) {
    return new Selector<S, any>(this, path, transformValue);
  };

  private emit = () => {
    const updatedKeys = this.updatedKeys;
    this.updatedKeys = [];
    const state = this.state;

    this.globalSubscribers.forEach(subscriber => subscriber(state));

    updatedKeys.forEach(key => {
      const subscriberKey = this.keySubscribers[key];

      if (subscriberKey) {
        subscriberKey.forEach(subscriber => subscriber(state[key]));
      }
    });
  };

  private createUnsubscribe(
    keys: ReadonlyArray<keyof S> | ReadonlyArray<never>,
    subscriber: Subscriber<unknown>
  ) {
    const unsubscribe = () => {
      if (!keys.length) {
        const globalIndex = this.globalSubscribers.indexOf(subscriber);
        if (globalIndex >= 0) {
          this.globalSubscribers.splice(globalIndex, 1);
        }
      } else {
        keys.forEach(key => {
          const subscriberKey = this.keySubscribers[key];

          if (subscriberKey) {
            const keyIndex = subscriberKey.indexOf(subscriber);

            if (keyIndex >= 0) {
              subscriberKey.splice(keyIndex, 1);

              if (!subscriberKey.length) {
                delete this.keySubscribers[key];
              }
            }
          }
        });
      }
    };

    return unsubscribe;
  }
}
