import * as React from 'react';

import History from './history';

export interface HistoryContext {
  history: History;
  location: Location;
  _href: string;
}

const Context = React.createContext<HistoryContext>({
  history: new History(),
  location: window.location,
  _href: window.location.href,
});

export default Context;
