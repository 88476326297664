import { createStore } from '../redax';
import { StoreState } from './types';

const INITIAL_REQUEST_STATE = {
  loading: false,
  data: null,
  inFlightCount: 0,
  error: undefined,
  requestCount: 0,
};

const store = createStore<StoreState>({
  requests: {
    user: { ...INITIAL_REQUEST_STATE },
    session: { ...INITIAL_REQUEST_STATE },
    sessions: { ...INITIAL_REQUEST_STATE },
    topic: { ...INITIAL_REQUEST_STATE },
    tempUser: { ...INITIAL_REQUEST_STATE },
    vote: { ...INITIAL_REQUEST_STATE },
  },
});

export default store;
