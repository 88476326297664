import * as React from 'react';

export interface FormValuesShape {
  [i: string]: any;
}

export interface FormStateShape {
  [i: string]: any;
}

type FormSubscriber<State extends FormStateShape> = (state: State) => void;

class FormStore<State extends FormStateShape, FormNames extends keyof State> {
  private subscribers: Array<FormSubscriber<State>> = [];
  private formSubscribers: Partial<
    {
      [F in FormNames]: Array<FormSubscriber<State[F]>>;
    }
  > = {};
  private state: State;

  public constructor(initialState: State) {
    this.state = initialState;
  }

  public setValues = <F extends FormNames>(formName: F, values: State[F]) => {
    this.state = {
      ...this.state,
      [formName]: {
        ...this.state[formName],
        ...values,
      },
    };

    this.emit();
    this.emitForm(formName);
  };

  public setValue = <F extends FormNames, N extends keyof State[F]>(
    formName: F,
    fieldName: N,
    value: State[F][N]
  ) => {
    this.state = {
      ...this.state,
      [formName]: {
        ...this.state[formName],
        [fieldName]: value,
      },
    };

    this.emit();
    this.emitForm(formName);
  };

  public subscribe = (callback: FormSubscriber<State>) => {
    if (this.subscribers.indexOf(callback) < 0) {
      this.subscribers.push(callback);
    }

    const unsubscribe = () => {
      const index = this.subscribers.indexOf(callback);

      if (index >= 0) {
        this.subscribers.splice(index, 1);
      }
    };

    return unsubscribe;
  };

  public subscribeToForm = <F extends FormNames>(
    formName: F,
    callback: FormSubscriber<State[F]>
  ) => {
    const formSubscribers = this.formSubscribers[formName];

    if (!formSubscribers) {
      this.formSubscribers[formName] = [callback];
    } else if (formSubscribers.indexOf(callback) < 0) {
      formSubscribers.push(callback);
    }

    const unsubscribeFromForm = () => {
      const formSubscribersUn = this.formSubscribers[formName];

      if (!formSubscribersUn) {
        return;
      }

      const index = formSubscribersUn.indexOf(callback);

      if (index >= 0) {
        formSubscribersUn.splice(index, 1);
      }
    };

    return unsubscribeFromForm;
  };

  public getValues = <F extends FormNames>(formName: F): State[F] => {
    return this.state[formName];
  };

  private emit() {
    const { state } = this;

    this.subscribers.forEach(subscriber => {
      subscriber(state);
    });
  }

  private emitForm<F extends FormNames>(formName: F) {
    const state = this.state[formName];
    this.formSubscribers[formName]?.forEach(subscriber => {
      subscriber(state);
    });
  }
}

const createFormStore = <State extends FormStateShape>(
  initialValues: State
) => {
  return new FormStore(initialValues);
};

const FormStoreContext = React.createContext<FormStore<any, any>>(
  (undefined as unknown) as FormStore<any, any>
);

export type FormProviderProps<
  State extends FormStateShape,
  FormNames extends keyof State
> = React.PropsWithChildren<{
  value: FormStore<State, FormNames>;
}>;

const FormProvider = <
  State extends FormStateShape,
  FormNames extends keyof State
>(
  props: FormProviderProps<State, FormNames>
) => (
  <FormStoreContext.Provider value={props.value}>
    {props.children}
  </FormStoreContext.Provider>
);

export type FormProps<
  Values extends FormValuesShape
> = React.PropsWithChildren<{
  onSubmit: (values: Values, event: React.FormEvent) => void;
  className?: string;
}>;

export type TextFieldProps<F extends string> = {
  multiline?: boolean;
  name: F;
} & React.InputHTMLAttributes<HTMLInputElement>;

const createForm = <
  State extends FormStateShape,
  F extends keyof State = keyof State
>(
  formName: F
) => {
  const Form = (props: FormProps<State[F]>) => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    if (!store) {
      throw new Error(
        'Form was rendered outside the context of a FormProvider'
      );
    }

    const onSubmit = React.useCallback(
      (event: React.FormEvent) => {
        event.preventDefault();
        props.onSubmit(store.getValues(formName), event);
      },
      [props.onSubmit, store]
    );

    return (
      <form onSubmit={onSubmit} className={props.className}>
        {props.children}
      </form>
    );
  };

  const TextField = <N extends Exclude<keyof State[F], number | symbol>>(
    props: TextFieldProps<N>
  ) => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    if (!store) {
      throw new Error(
        'TextField was rendered outside the context of a FormProvider'
      );
    }

    const [value, setValue] = React.useState<State[F][N]>(
      store.getValues(formName)[props.name]
    );

    React.useEffect(() => {
      return store.subscribeToForm<F>(formName, values => {
        setValue(values[props.name]);
      });
    }, []);

    const onChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        store.setValue(formName, props.name, event.currentTarget.value);
        if (typeof props.onChange === 'function') {
          props.onChange(event);
        }
      },
      []
    );

    return (
      <input {...props} type="text" value={value ?? ''} onChange={onChange} />
    );
  };

  const PasswordField = <N extends Exclude<keyof State[F], number | symbol>>(
    props: TextFieldProps<N>
  ) => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    if (!store) {
      throw new Error(
        'TextField was rendered outside the context of a FormProvider'
      );
    }

    const [value, setValue] = React.useState<State[F][N]>(
      store.getValues(formName)[props.name]
    );

    React.useEffect(() => {
      return store.subscribeToForm(formName, values => {
        setValue(values[props.name]);
      });
    }, []);

    const onChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        store.setValue(formName, props.name, event.currentTarget.value);
        if (typeof props.onChange === 'function') {
          props.onChange(event);
        }
      },
      []
    );

    return (
      <input
        {...props}
        type="password"
        value={value ?? ''}
        onChange={onChange}
      />
    );
  };

  const useInitialValues = (initialValues: State[F]) => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    if (!store) {
      throw new Error(
        'useInitialValues was used outside the context of a FormProvider'
      );
    }

    const [hasRun, setHasRun] = React.useState(false);

    if (!hasRun) {
      store.setValues(formName, initialValues);
      setHasRun(true);
    }
  };

  const useValues = (): State[F] => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    if (!store) {
      throw new Error(
        'useValues was used outside the context of a FormProvider'
      );
    }

    const [values, setValues] = React.useState(store.getValues(formName));

    React.useEffect(() => {
      return store.subscribeToForm(formName, nextValues => {
        setValues(nextValues);
      });
    }, [store]);

    return values;
  };

  const useSetValue = () => {
    const store = React.useContext<FormStore<State, F>>(FormStoreContext);

    const setValue = <N extends Exclude<keyof State[F], number | symbol>>(
      fieldName: N,
      value: State[F][N]
    ) => {
      store.setValue(formName, fieldName, value);
    };

    return React.useCallback(setValue, [store]);
  };

  return {
    Form: React.memo(Form),
    TextField: React.memo(TextField),
    PasswordField: React.memo(PasswordField),
    useValues,
    useInitialValues,
    useSetValue,
  };
};

export { createFormStore, FormProvider, createForm };
