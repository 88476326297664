import { Store } from './store';
import { DeepReadonly, SelectorPath } from './types';

export class Selector<S, V> {
  public path: SelectorPath;
  private previousState: DeepReadonly<S>;
  private previousValue: V;
  private transform?: {
    previousValue: V;
    transformValue: (value: any) => V;
  };
  private store: Store<S>;

  public constructor(
    store: Store<S>,
    path: SelectorPath,
    transformValue?: (value: any) => V
  ) {
    const state = store.getState();
    this.store = store;
    this.path = path;
    this.previousState = state;
    this.previousValue = this.get(state, path);

    if (transformValue) {
      this.transform = {
        transformValue,
        previousValue: transformValue(this.previousValue),
      };
    }
  }

  public resolve(): V {
    const state = this.store.getState();

    if (state === this.previousState) {
      if (this.transform) {
        return this.transform.previousValue;
      }

      return this.previousValue;
    }

    this.previousState = state;

    const newValue = this.get(state, this.path);

    if (newValue === this.previousValue) {
      if (this.transform) {
        return this.transform.previousValue;
      }

      return this.previousValue;
    }

    this.previousValue = newValue;

    if (this.transform) {
      this.transform.previousValue = this.transform.transformValue(
        this.previousValue
      );
      return this.transform.previousValue;
    }

    return this.previousValue;
  }

  private get = <T>(node: T, path: SelectorPath): any => {
    if (!path.length) {
      return node;
    }

    const [key, ...restPath] = path;
    // Guaranteed to be a key of T due to the type of Select
    const nextNode = node?.[key as keyof T];

    return this.get(nextNode, restPath);
  };
}
