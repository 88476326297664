import * as React from 'react';

import { handleKeys } from './utils';

interface Props {
  action: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
  title: string;
  children: React.ReactNode;
  className?: string;
}

const ActionableLink = (props: Props) => {
  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      const performAction = () => {
        event.preventDefault();
        props.action(event);
      };

      handleKeys(event, {
        ENTER: performAction,
        SPACE: performAction,
      });
    },
    [props.action]
  );

  return (
    <a
      className={props.className}
      onClick={props.action}
      tabIndex={0}
      title={props.title}
      onKeyDown={onKeyDown}
    >
      {props.children}
    </a>
  );
};

export default ActionableLink;
