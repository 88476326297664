import * as React from 'react';

import modalStore from './store';

export enum ModalSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export const ModalCancelled = Symbol('Modal closed');

export interface ModalComponentProps<T> {
  resolve: (result: T) => void;
  reject: (error: string | Error | typeof ModalCancelled) => void;
}

const openModal = <T extends any = unknown>(
  component: React.ComponentType<ModalComponentProps<T>>,
  size: ModalSize = ModalSize.SMALL
) => {
  const Component = component;

  return new Promise<T>((resolveP, rejectP) => {
    const resolve = (result: T) => {
      modalStore.closeLastModal();
      resolveP(result);
    };

    const reject = () => {
      modalStore.closeLastModal();
      rejectP(ModalCancelled);
    };

    modalStore.openModal(
      <div className={`modal ${size}`}>
        <Component resolve={resolve} reject={reject} />
      </div>
    );
  });
};

export default openModal;
