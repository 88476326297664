import * as React from 'react';

import { CLIENT_URLS } from '../shared/urls';
import Admin from './admin';
import AdminLogin from './admin/login';
import AdminNavBar from './admin/nav-bar';
import BrainSession from './brain-session';
import NotFound from './not-found';
import { connect, PrepareActions } from './redax';
import { Redirect, Route, Switch } from './router';
import { getUser } from './store/actions';
import { StoreState, User } from './store/types';
import { userIsAdmin } from './utils';

type ActionProps = PrepareActions<
  StoreState,
  {
    getUser: typeof getUser;
  }
>;

interface StateProps {
  userRequestLoading: boolean;
  userRequestCount: number;
  user: User | null;
}

type Props = StateProps & ActionProps;

const App = (props: Props) => {
  React.useEffect(() => {
    props.getUser();
  }, []);

  if (!props.userRequestCount || props.userRequestLoading) {
    return (
      <main className="loading">
        <h1>Loading...</h1>
      </main>
    );
  }

  const isAdmin = userIsAdmin(props.user);

  return (
    <>
      {isAdmin && <AdminNavBar />}
      <Switch>
        {!isAdmin && (
          <Redirect path={CLIENT_URLS.ADMIN} to={CLIENT_URLS.ADMIN_LOGIN} />
        )}
        {isAdmin && (
          <Redirect path={CLIENT_URLS.ADMIN_LOGIN} to={CLIENT_URLS.ADMIN} />
        )}
        <Route path={CLIENT_URLS.ADMIN} component={Admin} />
        <Route path={CLIENT_URLS.ADMIN_LOGIN} component={AdminLogin} />
        <Route
          path={CLIENT_URLS.BRAIN_SESSION_SPECIFIC.getURLTemplate()}
          component={BrainSession}
        />
        <Route path="/:any*" component={NotFound} />
      </Switch>
    </>
  );
};

export default connect<StoreState, StateProps, ActionProps>(
  utils => ({
    userRequestLoading: utils.select(['requests', 'user', 'loading']),
    userRequestCount: utils.select(['requests', 'user', 'requestCount']),
    user: utils.select(['requests', 'user', 'data']),
  }),
  { getUser }
)(App);
