import * as classNames from 'classnames';
import * as React from 'react';

import { IconProps } from './types';

function IconCheck({ active, className, ...props }: IconProps) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
      className={classNames('icon', { active }, className)}
    >
      <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436L24 5.782z" />
    </svg>
  );
}

export default IconCheck;
