import * as React from 'react';

import ActionableLink from '../actionable-link';
import { connect, PrepareActions } from '../redax';
import { Link } from '../router';
import { logout } from '../store/actions';
import { StoreState } from '../store/types';

type ActionProps = PrepareActions<
  StoreState,
  {
    logout: typeof logout;
  }
>;

type Props = ActionProps;

const AdminNavBar = (props: Props) => (
  <nav className="admin-nav-bar">
    <Link href="/admin" title="Go to admin home page">
      Admin home
    </Link>
    <ActionableLink className="flex-right" title="Logout" action={props.logout}>
      Logout
    </ActionableLink>
  </nav>
);

export default connect(undefined, { logout })(AdminNavBar);
