import * as React from 'react';
import { ReactNode } from 'react';

import Context from './context';
import History from './history';

interface RouterProps {
  history: History;
  children: ReactNode;
}

const Router = (props: RouterProps) => {
  const [location, setLocation] = React.useState(window.location);
  const [_href, setHref] = React.useState(window.location.href);

  React.useEffect(
    () =>
      props.history.onChange(nextLocation => {
        setLocation(nextLocation);
        setHref(nextLocation.href);
      }),
    []
  );

  return (
    <Context.Provider value={{ history: props.history, location, _href }}>
      {props.children}
    </Context.Provider>
  );
};

export default React.memo(Router);
