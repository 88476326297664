import * as React from 'react';
import { ComponentType, FunctionComponent } from 'react';

import Context from './context';
import History from './history';

export interface InjectedRouteProps {
  history: History;
  location: Location;
}

const withRouter = <OwnProps extends {}>(
  component: ComponentType<InjectedRouteProps & OwnProps>
) => {
  const Component = component;

  const WithRouter: FunctionComponent<OwnProps> = props => {
    const context = React.useContext(Context);

    return <Component {...props} {...context} />;
  };

  WithRouter.displayName = `WithRouter(${component.displayName ||
    component.name ||
    'Unknown'})`;

  return WithRouter;
};

export default withRouter;
