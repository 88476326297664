type HistoryCallback = (location: Location) => void;

export default class History {
  private listeners: HistoryCallback[] = [];

  public constructor() {
    window.addEventListener('popstate', () => {
      this.emitChange();
    });
  }

  public push(href: string) {
    const title = document.querySelector('title')?.textContent ?? '';
    window.history.pushState(null, title, href);

    this.emitChange();
  }

  public replace(href: string) {
    const title = document.querySelector('title')?.textContent ?? '';
    window.history.replaceState(null, title, href);

    this.emitChange();
  }

  public onChange(callback: HistoryCallback) {
    if (this.listeners.indexOf(callback) < 0) {
      this.listeners.push(callback);
    }

    return this.createUnsubscribe(callback);
  }

  private createUnsubscribe(callback: HistoryCallback) {
    const unsubscribe = () => {
      const index = this.listeners.indexOf(callback);

      if (index >= 0) {
        this.listeners.splice(index, 1);
      }
    };

    return unsubscribe;
  }

  private emitChange() {
    const { location } = window;

    this.listeners.forEach(listener => {
      listener(location);
    });
  }
}
