import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import ActionableLink from './actionable-link';

const noop = () => null;

const ToastCloseButton = ({
  closeToast = noop,
}: {
  closeToast?: () => void;
}) => (
  <ActionableLink action={closeToast} title="Close">
    <FontAwesomeIcon icon={faTimes} />
  </ActionableLink>
);

export default ToastCloseButton;
