import * as classNames from 'classnames';
import * as React from 'react';

import ActionableLink from '../actionable-link';
import { connect, MapStateUtils, PrepareActions } from '../redax';
import { createBrainSession, getBrainSessions } from '../store/actions';
import { BrainSession, RequestState, StoreState } from '../store/types';
import Session from './session';

type ActionProps = PrepareActions<
  StoreState,
  {
    createBrainSession: typeof createBrainSession;
    getBrainSessions: typeof getBrainSessions;
  }
>;

interface StateProps {
  sessions: RequestState<readonly BrainSession[]>;
}

type Props = StateProps & ActionProps;

const Admin = (props: Props) => {
  React.useEffect(() => {
    props.getBrainSessions();
  }, []);

  return (
    <main className={classNames('admin', { fade: props.sessions.loading })}>
      <div className="display-flex margin-bottom-base">
        <h1 className="flex-left margin-vertical-none">Admin</h1>
        <ActionableLink
          className="flex-right"
          title="Create brain session"
          action={props.createBrainSession}
        >
          Create brain session
        </ActionableLink>
      </div>
      <ul className="grid">
        {props.sessions.data?.map(session => (
          <Session key={session.id} session={session} />
        ))}
      </ul>
    </main>
  );
};

export default connect(
  (utils: MapStateUtils<StoreState>) => ({
    sessions: utils.select(['requests', 'sessions']),
  }),
  { createBrainSession, getBrainSessions }
)(Admin);
