import { TSURL } from '@jakesidsmith/tsurl';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { BrainSessionState } from '../../shared/constants';
import { API_URLS } from '../../shared/urls';
import { AdminLoginData, TopicData } from '../forms/types';
import openModal, { ModalCancelled } from '../modal/open';
import createPrompt from '../modal/prompt';
import { ActionUtils } from '../redax';
import { getErrorString } from '../utils';
import { StoreState, Topic } from './types';

export const displayError = (error: string) => {
  toast.error(error);
};

function createRequestAction<Data extends unknown = never>(
  key: keyof StoreState['requests'],
  config: AxiosRequestConfig,
  callback?: (
    error: AxiosError | Error | string | null,
    response: AxiosResponse | null
  ) => void
): (
  utils: ActionUtils<StoreState>
) => undefined extends Data
  ? () => Promise<never>
  : (data: Data) => Promise<never>;
function createRequestAction<Data extends unknown = never>(
  key: keyof StoreState['requests'],
  config: AxiosRequestConfig,
  callback?: (
    error: AxiosError | Error | string | null,
    response: AxiosResponse | null
  ) => void
) {
  return (utils: ActionUtils<StoreState>) => (data?: Data) => {
    utils.update('requests', requests => {
      const thisRequest = requests[key];

      return {
        ...requests,
        [key]: {
          ...thisRequest,
          inFlightCount: thisRequest.inFlightCount + 1,
          loading: thisRequest.inFlightCount >= 0,
          requestCount: thisRequest.requestCount + 1,
        },
      };
    });

    return axios
      .request({ ...config, data })
      .then(response => {
        utils.update('requests', requests => {
          const thisRequest = requests[key];

          return {
            ...requests,
            [key]: {
              ...thisRequest,
              data: response.data,
              inFlightCount: thisRequest.inFlightCount - 1,
              loading: thisRequest.inFlightCount > 1,
              error: undefined,
            },
          };
        });

        if (typeof callback === 'function') {
          callback(null, response);
        }
      })
      .catch((error: AxiosError | Error | string) => {
        if (
          typeof error === 'object' &&
          'response' in error &&
          error.response?.status === 403
        ) {
          utils.update('requests', requests => {
            const userRequest = requests.user;

            return {
              ...requests,
              user: {
                ...userRequest,
                data: null,
              },
            };
          });
        }

        utils.update('requests', requests => {
          const thisRequest = requests[key];

          const errorString = getErrorString(error);

          if (
            !(
              config.url === API_URLS.ME &&
              typeof error === 'object' &&
              'response' in error &&
              error.response?.status === 401
            )
          ) {
            displayError(errorString);
          }

          return {
            ...requests,
            [key]: {
              ...thisRequest,
              error: errorString,
              inFlightCount: thisRequest.inFlightCount - 1,
              loading: thisRequest.inFlightCount > 1,
            },
          };
        });

        if (typeof callback === 'function') {
          callback(error, null);
        }
      });
  };
}

export const createRequestActionNoData = (
  key: keyof StoreState['requests'],
  config: AxiosRequestConfig,
  callback?: (
    error: AxiosError | Error | string | null,
    response: AxiosResponse | null
  ) => void
) => (utils: ActionUtils<StoreState>) => () => {
  return createRequestAction<undefined>(key, config, callback)(utils)();
};

export const login = createRequestAction<AdminLoginData>('user', {
  url: API_URLS.LOGIN,
  method: 'POST',
});

export const logout = createRequestActionNoData('user', {
  url: API_URLS.LOGOUT,
  method: 'POST',
});

export const getUser = createRequestActionNoData('user', {
  url: API_URLS.ME,
  method: 'GET',
});

export const createTempUser = (utils: ActionUtils<StoreState>) => (data: {
  url: string;
}) => {
  createRequestAction<{ url: string }>('tempUser', {
    url: API_URLS.CREATE_TEMP_USER,
    method: 'POST',
  })(utils)(data).then(() => {
    getUser(utils)();
  });
};

export const getBrainSessions = createRequestActionNoData('sessions', {
  url: API_URLS.BRAIN_SESSION,
  method: 'GET',
});

export const changeBrainSessionState = (utils: ActionUtils<StoreState>) => (
  slug: string,
  state: BrainSessionState
) => {
  createRequestAction<{ state: string }>('session', {
    url: API_URLS.BRAIN_SESSION_STATE.construct({ slug }, {}),
    method: 'POST',
  })(utils)({ state });
};

export const addOrUpdateTopicInState = (utils: ActionUtils<StoreState>) => (
  topic: Topic
) => {
  utils.update('requests', requests => {
    const sessionRequest = requests.session;
    const topics = sessionRequest.data?.topics ?? [];

    return {
      ...requests,
      session: {
        ...sessionRequest,
        data: sessionRequest.data
          ? {
              ...sessionRequest.data,
              topics: topics.find(top => top.id === topic.id)
                ? topics.map(top => (top.id === topic.id ? topic : top))
                : [topic, ...topics],
            }
          : null,
      },
    };
  });
};

export const deleteTopicInState = (utils: ActionUtils<StoreState>) => (
  topicId: string
) => {
  utils.update('requests', requests => {
    const sessionRequest = requests.session;
    const topics = sessionRequest.data?.topics ?? [];

    return {
      ...requests,
      session: {
        ...sessionRequest,
        data: sessionRequest.data
          ? {
              ...sessionRequest.data,
              topics: topics.filter(top => top.id !== topicId),
            }
          : null,
      },
    };
  });
};

export const submitTopic = (utils: ActionUtils<StoreState>) => (
  slug: string,
  text: string
) => {
  createRequestAction<TopicData>(
    'topic',
    {
      url: API_URLS.BRAIN_SESSION_TOPIC.construct({ slug }, {}),
      method: 'POST',
    },
    (error, result) => {
      if (!error && result) {
        addOrUpdateTopicInState(utils)(result.data);
      }
    }
  )(utils)({ text });
};

export const removeTopic = (utils: ActionUtils<StoreState>) => (
  topicId: string
) => {
  createRequestActionNoData(
    'topic',
    {
      url: API_URLS.TOPIC.construct({ topicId }, {}),
      method: 'DELETE',
    },
    (error, result) => {
      if (!error && result) {
        deleteTopicInState(utils)(topicId);
      }
    }
  )(utils)();
};

export const getBrainSession = (utils: ActionUtils<StoreState>) => (
  slug: string
) => {
  createRequestActionNoData('session', {
    url: API_URLS.BRAIN_SESSION_SPECIFIC.construct({ slug }, {}),
    method: 'GET',
  })(utils)();
};

const BrainSessionPrompt = createPrompt({
  title: 'Create a new brain session',
  label: 'Brain session title (should include the date)',
  value: 'Ask The Brains (Date Here)',
  cancellable: true,
});

export const createBrainSession = (
  utils: ActionUtils<StoreState>
) => async () => {
  let title = await openModal(BrainSessionPrompt).catch(
    (error: Error | typeof ModalCancelled) => {
      if (error === ModalCancelled) {
        return error;
      }

      alert(getErrorString(error));
    }
  );

  if (title === ModalCancelled) {
    return;
  }

  title = title?.trim();

  if (!title) {
    alert('You must enter a title for the session');
  } else {
    createRequestAction<{ title: string }>(
      'session',
      {
        url: API_URLS.BRAIN_SESSION,
        method: 'POST',
      },
      (error, response) => {
        if (error === null && response !== null) {
          utils.update('requests', requests => {
            const sessions = requests.sessions;
            const data = sessions.data ?? [];

            return {
              ...requests,
              sessions: {
                ...sessions,
                data: [response.data, ...data],
              },
            };
          });
        }
      }
    )(utils)({ title });
  }
};

const createTopicAction = (
  url: TSURL<'topicId'>,
  method: 'POST' | 'DELETE',
  key: keyof StoreState['requests']
) => (utils: ActionUtils<StoreState>) => (topicId: string) => {
  createRequestActionNoData(
    key,
    {
      url: url.construct({ topicId }, {}),
      method,
    },
    (error, result) => {
      if (!error && result) {
        utils.update('requests', requests => {
          const sessionRequest = requests.session;

          return {
            ...requests,
            session: {
              ...sessionRequest,
              data: sessionRequest.data
                ? {
                    ...sessionRequest.data,
                    topics: sessionRequest.data.topics.map(topic =>
                      topic.id === topicId ? result.data : topic
                    ),
                  }
                : null,
            },
          };
        });
      }
    }
  )(utils)();
};

export const addVote = createTopicAction(API_URLS.TOPIC_VOTE, 'POST', 'vote');
export const removeVote = createTopicAction(
  API_URLS.TOPIC_VOTE,
  'DELETE',
  'vote'
);

export const approveTopic = createTopicAction(
  API_URLS.TOPIC_APPROVE,
  'POST',
  'topic'
);
export const rejectTopic = createTopicAction(
  API_URLS.TOPIC_REJECT,
  'POST',
  'topic'
);

export const completeTopic = createTopicAction(
  API_URLS.TOPIC_COMPLETE,
  'POST',
  'topic'
);
export const unCompleteTopic = createTopicAction(
  API_URLS.TOPIC_UN_COMPLETE,
  'POST',
  'topic'
);
