export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const USER_SESSION_COOKIE = 'brains-user-session';

export enum BrainSessionState {
  CREATED = 'CREATED',
  SUBMIT = 'SUBMIT',
  AUDIT = 'AUDIT',
  VOTING = 'VOTING',
  RESULTS = 'RESULTS',
  CLOSED = 'CLOSED',
}

export const ADMIN_ONLY_STATES = [
  BrainSessionState.CREATED,
  BrainSessionState.AUDIT,
];

export enum TopicState {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;
