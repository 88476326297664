import History from './history';
import Link from './link';
import Redirect from './redirect';
import Route from './route';
import Router from './router';
import Switch from './switch';
import withRouter, { InjectedRouteProps } from './with-router';

export {
  History,
  withRouter,
  InjectedRouteProps,
  Router,
  Redirect,
  Route,
  Switch,
  Link,
};
