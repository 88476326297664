import * as React from 'react';

import withRouter, { InjectedRouteProps } from './with-router';

export interface RedirectProps {
  path: string;
  to: string;
}

type Props = RedirectProps & InjectedRouteProps;

const Redirect = (props: Props) => {
  React.useEffect(() => {
    props.history.push(props.to);
  }, []);
  return null;
};

export default withRouter(Redirect);
