import createTSURL, { requiredString } from '@jakesidsmith/tsurl';

export const API_URLS = {
  ANY: '/api/*',
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  ME: '/api/me',
  CREATE_TEMP_USER: '/api/user/temp',
  BRAIN_SESSION: '/api/session',
  BRAIN_SESSION_SPECIFIC: createTSURL(['/api/brain', requiredString('slug')], {
    trailingSlash: false,
  }),
  BRAIN_SESSION_STATE: createTSURL(
    ['/api/brain', requiredString('slug'), 'state'],
    {
      trailingSlash: false,
    }
  ),
  BRAIN_SESSION_TOPIC: createTSURL(
    ['/api/brain', requiredString('slug'), 'topic'],
    {
      trailingSlash: false,
    }
  ),
  TOPIC: createTSURL(['/api/topic', requiredString('topicId'), 'topic'], {
    trailingSlash: false,
  }),
  TOPIC_VOTE: createTSURL(['/api/topic', requiredString('topicId'), 'vote'], {
    trailingSlash: false,
  }),
  TOPIC_APPROVE: createTSURL(
    ['/api/topic', requiredString('topicId'), 'approve'],
    {
      trailingSlash: false,
    }
  ),
  TOPIC_REJECT: createTSURL(
    ['/api/topic', requiredString('topicId'), 'reject'],
    {
      trailingSlash: false,
    }
  ),
  TOPIC_COMPLETE: createTSURL(
    ['/api/topic', requiredString('topicId'), 'complete'],
    {
      trailingSlash: false,
    }
  ),
  TOPIC_UN_COMPLETE: createTSURL(
    ['/api/topic', requiredString('topicId'), 'un-complete'],
    {
      trailingSlash: false,
    }
  ),
};

export const CLIENT_URLS = {
  ADMIN: '/admin',
  ADMIN_LOGIN: '/admin/login',
  BRAIN_SESSION_SPECIFIC: createTSURL(['/session', requiredString('slug')], {
    trailingSlash: false,
  }),
};
