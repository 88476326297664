import * as classNames from 'classnames';
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as io from 'socket.io-client';

import { ADMIN_ONLY_STATES, BrainSessionState } from '../shared/constants';
import { API_URLS, CLIENT_URLS } from '../shared/urls';
import { createForm } from './create-form';
import { FormState, TopicData } from './forms/types';
import { connect, MapStateUtils, PrepareActions } from './redax';
import { InjectedRouteProps, withRouter } from './router';
import {
  addOrUpdateTopicInState,
  changeBrainSessionState,
  createTempUser,
  deleteTopicInState,
  getBrainSession,
  submitTopic,
} from './store/actions';
import {
  BrainSession,
  RequestState,
  StoreState,
  Topic,
  User,
} from './store/types';
import TopicRenderer from './topic-renderer';
import { userIsAdmin } from './utils';

type ActionProps = PrepareActions<
  StoreState,
  {
    getBrainSession: typeof getBrainSession;
    changeBrainSessionState: typeof changeBrainSessionState;
    submitTopic: typeof submitTopic;
    addOrUpdateTopicInState: typeof addOrUpdateTopicInState;
    deleteTopicInState: typeof deleteTopicInState;
    createTempUser: typeof createTempUser;
  }
>;

interface StateProps {
  session: RequestState<BrainSession>;
  user: RequestState<User>;
  vote: RequestState<unknown>;
  tempUser: StoreState['requests']['tempUser'];
}

type Props = StateProps & ActionProps & InjectedRouteProps;

const STATE_OPTIONS = [
  {
    label: 'Created',
    value: BrainSessionState.CREATED,
  },
  {
    label: 'Submit topics',
    value: BrainSessionState.SUBMIT,
  },
  {
    label: 'Audit topics',
    value: BrainSessionState.AUDIT,
  },
  {
    label: 'Voting',
    value: BrainSessionState.VOTING,
  },
  {
    label: 'Results',
    value: BrainSessionState.RESULTS,
  },
  {
    label: 'Closed',
    value: BrainSessionState.CLOSED,
  },
];

const TopicForm = createForm<FormState, 'topic'>('topic');

const BrainSession = (props: Props) => {
  const {
    urlParams: { slug },
  } = CLIENT_URLS.BRAIN_SESSION_SPECIFIC.deconstruct(props.location.pathname);

  const socket = React.useRef<SocketIOClient.Socket>();

  React.useEffect(() => {
    if (!props.user.data) {
      props.createTempUser({
        url: `${window.location.origin}${window.location.pathname}`,
      });
    }
  }, [props.user.data]);

  const isAdmin = userIsAdmin(props.user.data);
  const setFormValue = TopicForm.useSetValue();

  React.useEffect(() => {
    props.getBrainSession(slug);
  }, [slug, props.user.data]);

  React.useEffect(() => {
    if (props.user.data) {
      socket.current = io(
        API_URLS.BRAIN_SESSION_SPECIFIC.construct({ slug }, {})
      );

      socket.current.on('stateChange', () => {
        props.getBrainSession(slug);
      });

      socket.current.on('topicCreated', (topic: Topic) => {
        props.addOrUpdateTopicInState(topic);
      });

      socket.current.on('topicDeleted', (topicId: string) => {
        props.deleteTopicInState(topicId);
      });

      socket.current.on('topicApproved', (topic: Topic) => {
        props.addOrUpdateTopicInState(topic);
      });

      socket.current.on('topicRejected', (topic: Topic) => {
        if (props.user.data?.isAdmin) {
          props.addOrUpdateTopicInState(topic);
        } else if (!props.user.data || topic.creator !== props.user.data.id) {
          props.deleteTopicInState(topic.id);
        }
      });

      socket.current.on('topicCompleted', (topic: Topic) => {
        props.addOrUpdateTopicInState(topic);
      });

      socket.current.on('topicUnCompleted', (topic: Topic) => {
        props.addOrUpdateTopicInState(topic);
      });
    }

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [slug, props.user.data]);

  const onChangeState = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.changeBrainSessionState(
        slug,
        event.currentTarget.value as BrainSessionState
      );
    },
    [slug]
  );

  const onSubmitTopic = React.useCallback(({ text }: TopicData) => {
    const trimmed = text?.trim();

    if (trimmed) {
      props.submitTopic(slug, trimmed);
    }

    setFormValue('text', '');
  }, []);

  if (
    (props.session.loading && !props.session.data) ||
    props.tempUser.loading
  ) {
    return <main className="session fade" />;
  }

  if (!props.session.data) {
    return (
      <main className="session">
        <h1 className="margin-vertical-none">Failed to load brain session</h1>
      </main>
    );
  }

  return (
    <main className={classNames('session', { fade: props.session.loading })}>
      <div className="display-flex margin-bottom-base">
        <h1 className="flex-left margin-vertical-none">
          {props.session.data.title}
        </h1>
        {isAdmin && (
          <div className="form-group inline flex-right">
            <label htmlFor="state">{'State: '}</label>
            <select
              id="state"
              value={props.session.data.state}
              onChange={onChangeState}
            >
              {STATE_OPTIONS.map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                  {ADMIN_ONLY_STATES.includes(value) && ' (admin only)'}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.CREATED && (
          <p>
            This session is not yet accepting topic submissions. Come back soon.
          </p>
        )}
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.SUBMIT && (
          <p>
            Please enter a topic that you would like to be discussed by the
            group at the Ask the Brains session, ideally in the form of a
            question. Anything from "I'm new to development, where do I get
            started?" to "null, undefined, or both?". You can submit as many
            topics as you like.
          </p>
        )}
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.AUDIT && (
          <p>Topic submission has ended. Next up: voting!</p>
        )}
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.VOTING && (
          <p>It's voting time! Star any topics you'd like to be discussed.</p>
        )}
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.RESULTS && (
          <p>Here's the results.</p>
        )}
      {(!props.user.data || !isAdmin) &&
        props.session.data.state === BrainSessionState.CLOSED && (
          <p>
            This session is now closed, but you can still view the topics that
            were discussed.
          </p>
        )}
      {props.user.data &&
        (props.session.data.state === BrainSessionState.SUBMIT ||
          (isAdmin &&
            (props.session.data.state === BrainSessionState.CREATED ||
              props.session.data.state === BrainSessionState.AUDIT))) && (
          <TopicForm.Form
            onSubmit={onSubmitTopic}
            className="margin-bottom-large"
          >
            <div className="form-group">
              <label htmlFor="topic">
                Add a topic
                {isAdmin &&
                  ' (as an admin you can submit topics at any time, automatically approved)'}
              </label>
              <div className="input-group">
                <TopicForm.TextField
                  id="topic"
                  name="text"
                  placeholder="Enter a topic to discuss..."
                  maxLength={500}
                />
                <button
                  type="submit"
                  className="primary display-none sm-display-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </TopicForm.Form>
        )}
      <TransitionGroup className="grid" component="ul">
        {props.session.data.topics.map(topic => (
          <CSSTransition
            key={topic.id}
            classNames="topic-animation"
            timeout={300}
          >
            <TopicRenderer topic={topic} session={props.session.data!} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </main>
  );
};

export default connect(
  (utils: MapStateUtils<StoreState>) => ({
    session: utils.select(['requests', 'session']),
    user: utils.select(['requests', 'user']),
    vote: utils.select(['requests', 'vote']),
    tempUser: utils.select(['requests', 'tempUser']),
  }),
  {
    getBrainSession,
    changeBrainSessionState,
    submitTopic,
    addOrUpdateTopicInState,
    deleteTopicInState,
    createTempUser,
  }
)(withRouter(BrainSession));
