import * as React from 'react';
import { ComponentType } from 'react';

import withRouter, { InjectedRouteProps } from './with-router';

export interface RouteProps {
  path: string;
  component: ComponentType<InjectedRouteProps>;
}

type Props = RouteProps & InjectedRouteProps;

const Route = ({ path, component: Component, ...props }: Props) => (
  <Component {...props} />
);

export default withRouter(Route);
