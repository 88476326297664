import * as React from 'react';
import { ComponentType, FunctionComponent, MemoExoticComponent } from 'react';

import { UNKNOWN } from './constants';
import { RedaxContext } from './context';
import { Store } from './store';
import {
  Action,
  InferableConnector,
  MapSelectors,
  MapStateUtils,
  PrepareActions,
  UnPrepareActions,
} from './types';

const getDisplayName = (
  component: ComponentType<any> | MemoExoticComponent<ComponentType<any>>
) =>
  'type' in component
    ? component.type.displayName || component.type.name || UNKNOWN
    : component.displayName || component.name || UNKNOWN;

const runOnceWithStore = <S extends Record<string, any>>(
  callback: (store: Store<S>) => void,
  store: Store<S>
) => {
  const initialized = React.useRef(false);

  if (!initialized.current) {
    initialized.current = true;
    callback(store);
  }
};

interface Self<
  S extends Record<string, any>,
  StateProps extends Record<string, any>,
  ActionProps extends Record<string, any>
> {
  initialState: StateProps;
  preparedActions: PrepareActions<S, ActionProps>;
  subscriptionKeys: Array<keyof S>;
  selectors: MapSelectors<S, StateProps> | undefined;
  unsubscribe: (() => void) | undefined;
}

function connect<
  S extends Record<string, any>,
  StateProps extends Record<string, any>,
  NoActionProps extends PrepareActions<S, Record<string, Action<S>>> = {},
  ExternalProps extends Record<string, any> = {},
  NoTransformedProps extends Record<string, any> = {}
>(
  mapState: (utils: MapStateUtils<S>) => MapSelectors<S, StateProps>
): InferableConnector<StateProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  StateProps extends Record<string, any>,
  NoActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any>,
  TransformedProps extends Record<string, any>
>(
  mapState: (utils: MapStateUtils<S>) => MapSelectors<S, StateProps>,
  actions: undefined,
  transformProps: (
    stateProps: Readonly<StateProps>,
    actionProps: Readonly<{}>,
    externalProps: Readonly<ExternalProps>
  ) => TransformedProps
): InferableConnector<TransformedProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  NoStateProps extends Record<string, any>,
  ActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any> = {},
  NoTransformedProps extends Record<string, any> = {}
>(
  mapState: undefined,
  actions: UnPrepareActions<S, ActionProps>
): InferableConnector<ActionProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  NoStateProps extends Record<string, any>,
  ActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any>,
  TransformedProps extends Record<string, any>
>(
  mapState: undefined,
  actions: UnPrepareActions<S, ActionProps>,
  transformProps: (
    stateProps: Readonly<{}>,
    actionProps: Readonly<ActionProps>,
    externalProps: Readonly<ExternalProps>
  ) => TransformedProps
): InferableConnector<TransformedProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  NoStateProps extends Record<string, any>,
  NoActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any>,
  TransformedProps extends Record<string, any>
>(
  mapState: undefined,
  actions: undefined,
  transformProps: (
    stateProps: Readonly<{}>,
    actionProps: Readonly<{}>,
    externalProps: Readonly<ExternalProps>
  ) => TransformedProps
): InferableConnector<TransformedProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  StateProps extends Record<string, any>,
  ActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any> = {},
  NoTransformedProps extends Record<string, any> = {}
>(
  mapState: (utils: MapStateUtils<S>) => MapSelectors<S, StateProps>,
  actions: UnPrepareActions<S, ActionProps>
): InferableConnector<StateProps & ActionProps, ExternalProps>;

function connect<
  S extends Record<string, any>,
  StateProps extends Record<string, any>,
  ActionProps extends PrepareActions<S, Record<string, Action<S>>>,
  ExternalProps extends Record<string, any>,
  TransformedProps extends Record<string, any>
>(
  mapState: (utils: MapStateUtils<S>) => MapSelectors<S, StateProps>,
  actions: UnPrepareActions<S, ActionProps>,
  transformProps: (
    stateProps: Readonly<StateProps>,
    actionProps: Readonly<ActionProps>,
    externalProps: Readonly<ExternalProps>
  ) => TransformedProps
): InferableConnector<TransformedProps, ExternalProps>;

function connect<
  S extends Record<string, any> = {},
  StateProps extends Record<string, any> = {},
  ActionProps extends PrepareActions<S, Record<string, Action<S>>> = {},
  ExternalProps extends Record<string, any> = {},
  TransformedProps extends Record<string, any> = StateProps &
    ActionProps &
    ExternalProps
>(
  mapState?: (utils: MapStateUtils<S>) => MapSelectors<S, StateProps>,
  actions?: UnPrepareActions<S, ActionProps>,
  transformProps: (
    stateProps: StateProps,
    actionProps: ActionProps,
    externalProps: ExternalProps
  ) => TransformedProps = (stateProps, actionProps, externalProps) =>
    ({
      ...stateProps,
      ...actionProps,
      ...externalProps,
    } as TransformedProps)
) {
  return (
    Component: ComponentType<TransformedProps>
  ): MemoExoticComponent<FunctionComponent<ExternalProps>> => {
    const RedaxConnectedComponent = (props: ExternalProps) => {
      const context = React.useContext(
        RedaxContext as React.Context<undefined | Store<S>>
      );

      if (!context) {
        throw new Error(
          `Connected component "${getDisplayName(
            Component
          )}" was rendered outside the context of a Provider`
        );
      }

      const { current: self } = React.useRef<Self<S, StateProps, ActionProps>>({
        initialState: {} as StateProps,
        preparedActions: {} as PrepareActions<S, ActionProps>,
        subscriptionKeys: [],
        selectors: undefined,
        unsubscribe: undefined,
      });

      runOnceWithStore(store => {
        for (const key in actions) {
          // istanbul ignore else
          if (Object.prototype.hasOwnProperty.call(actions, key)) {
            const action = actions[key];

            self.preparedActions[key] = store.prepareAction(action);
          }
        }

        if (mapState) {
          self.selectors = mapState(context);

          for (const key in self.selectors) {
            // istanbul ignore else
            if (Object.prototype.hasOwnProperty.call(self.selectors, key)) {
              self.initialState[key] = self.selectors[key].resolve();
            }
          }
        }
      }, context);

      const [state, setState] = React.useState(self.initialState);

      runOnceWithStore(store => {
        for (const key in self.selectors) {
          // istanbul ignore else
          if (Object.prototype.hasOwnProperty.call(self.selectors, key)) {
            const selector = self.selectors[key];
            // Guaranteed to be a key of T due to the type of Select
            const firstKey = selector.path[0] as keyof S;

            if (self.subscriptionKeys.indexOf(firstKey) < 0) {
              self.subscriptionKeys.push(firstKey);
            }
          }
        }

        if (self.subscriptionKeys.length) {
          self.unsubscribe = store.subscribe(self.subscriptionKeys, () => {
            const newState = {} as StateProps;

            for (const key in self.selectors) {
              // istanbul ignore else
              if (Object.prototype.hasOwnProperty.call(self.selectors, key)) {
                newState[key] = self.selectors[key].resolve();
              }
            }

            setState(newState);
          });
        }
      }, context);

      React.useEffect(() => self.unsubscribe, []);

      return (
        <Component {...transformProps(state, self.preparedActions, props)} />
      );
    };

    RedaxConnectedComponent.displayName = `RedaxConnected(${getDisplayName(
      Component
    )})`;

    return React.memo(RedaxConnectedComponent);
  };
}

export { connect };
