import * as React from 'react';

import { CLIENT_URLS } from '../shared/urls';

const NotFound = () => (
  <main className="not-found">
    <h1>Not found</h1>
    <p>This page doesn't exist...</p>
    <p>How did you get here?</p>
    <p>Did Steve send you here?</p>
    <p>
      Make sure the URL you have ends with{' '}
      <code>
        {CLIENT_URLS.BRAIN_SESSION_SPECIFIC.construct(
          { slug: 'session-name' },
          {}
        )}
      </code>
    </p>
  </main>
);

export default React.memo(NotFound);
