import { format } from 'date-fns';
import * as React from 'react';

import { CLIENT_URLS } from '../../shared/urls';
import { Link } from '../router';
import { BrainSession } from '../store/types';

interface OwnProps {
  session: BrainSession;
}

type Props = OwnProps;

const Session = (props: Props) => (
  <li className="item">
    <div className="content-box">
      <div className="display-flex">
        <h2 className="flex-left">{props.session.title}</h2>
        <Link
          className="flex-right"
          title="View brain session"
          href={CLIENT_URLS.BRAIN_SESSION_SPECIFIC.construct(
            { slug: props.session.slug },
            {}
          )}
        >
          View
        </Link>
      </div>
      <span className="info">
        Created: {format(new Date(props.session.created), 'do MMM, y (HH:mm)')}
      </span>
    </div>
  </li>
);

export default React.memo(Session);
