import * as classNames from 'classnames';
import * as React from 'react';

import { BrainSessionState, TopicState } from '../shared/constants';
import IconCheck from './icons/check';
import IconCross from './icons/cross';
import IconStar from './icons/star';
import IconThumbDown from './icons/thumb-down';
import IconThumbUp from './icons/thumb-up';
import { connect, MapStateUtils, PrepareActions } from './redax';
import {
  addVote,
  approveTopic,
  completeTopic,
  rejectTopic,
  removeTopic,
  removeVote,
  unCompleteTopic,
} from './store/actions';
import { BrainSession, StoreState, Topic, User } from './store/types';

type ActionsProps = PrepareActions<
  StoreState,
  {
    addVote: typeof addVote;
    removeVote: typeof removeVote;
    removeTopic: typeof removeTopic;
    approveTopic: typeof approveTopic;
    rejectTopic: typeof rejectTopic;
    unCompleteTopic: typeof unCompleteTopic;
    completeTopic: typeof completeTopic;
  }
>;

interface StateProps {
  user: User | null;
}

interface OwnProps {
  topic: Topic;
  session: BrainSession;
}

type Props = StateProps & ActionsProps & OwnProps;

const TopicRenderer = (props: Props) => {
  const onClickThumbUp = React.useCallback(() => {
    props.approveTopic(props.topic.id);
  }, [props.topic]);

  const onClickThumbDown = React.useCallback(() => {
    props.rejectTopic(props.topic.id);
  }, [props.topic]);

  const onClickCross = React.useCallback(() => {
    props.removeTopic(props.topic.id);
  }, [props.topic]);

  const onClickVote = React.useCallback(() => {
    if (props.user) {
      if (props.topic.votes?.includes(props.user.id)) {
        props.removeVote(props.topic.id);
      } else {
        props.addVote(props.topic.id);
      }
    }
  }, [props.topic, props.user]);

  const onClickCheck = React.useCallback(() => {
    if (props.topic.completed) {
      props.unCompleteTopic(props.topic.id);
    } else {
      props.completeTopic(props.topic.id);
    }
  }, [props.topic]);

  const isPreVote =
    props.session.state === BrainSessionState.CREATED ||
    props.session.state === BrainSessionState.SUBMIT ||
    props.session.state === BrainSessionState.AUDIT;

  return (
    <li className="item">
      <div
        className={classNames('content-box', {
          attention:
            props.user?.isAdmin && props.topic.state === TopicState.CREATED,
          completed:
            (props.session.state === BrainSessionState.RESULTS ||
              props.session.state === BrainSessionState.CLOSED) &&
            props.topic.completed,
        })}
      >
        <p className="topic-text">{props.topic.text}</p>
        {props.user && (
          <div className="icons upper">
            {props.user.isAdmin && isPreVote && (
              <IconThumbUp
                onClick={onClickThumbUp}
                active={props.topic.state === TopicState.APPROVED}
              />
            )}
            {props.user.isAdmin && isPreVote && (
              <IconThumbDown
                onClick={onClickThumbDown}
                active={props.topic.state === TopicState.REJECTED}
              />
            )}
            {(props.session.state === BrainSessionState.SUBMIT ||
              (props.user.isAdmin && isPreVote)) &&
              props.topic.creator === props.user.id && (
                <IconCross onClick={onClickCross} />
              )}
            {props.user.isAdmin &&
              props.session.state === BrainSessionState.RESULTS && (
                <IconCheck
                  onClick={onClickCheck}
                  active={props.topic.completed}
                />
              )}
          </div>
        )}
        {props.user && (
          <div className="icons lower">
            {props.session.state === BrainSessionState.VOTING && (
              <IconStar
                onClick={onClickVote}
                active={props.topic.votes?.includes(props.user.id)}
              />
            )}
            {(props.session.state === BrainSessionState.RESULTS ||
              props.session.state === BrainSessionState.CLOSED) && (
              <div className="votes">{props.topic.votes?.length || 0}</div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default connect(
  (utils: MapStateUtils<StoreState>) => ({
    user: utils.select(['requests', 'user', 'data']),
  }),
  {
    addVote,
    removeVote,
    removeTopic,
    approveTopic,
    rejectTopic,
    unCompleteTopic,
    completeTopic,
  }
)(TopicRenderer);
