export interface ModalDefinition {
  id: number;
  element: React.ReactElement;
}

type Subscriber = (modals: ReadonlyArray<ModalDefinition>) => void;

class ModalStore {
  private latestModalId: number = 0;
  private subscribers: Subscriber[] = [];
  private state: ReadonlyArray<ModalDefinition> = [];

  public openModal = (element: React.ReactElement) => {
    this.latestModalId += 1;
    this.state = [...this.state, { id: this.latestModalId, element }];
    this.emitChange();
  };

  public closeLastModal = () => {
    const stateCopy = [...this.state];
    stateCopy.pop();
    this.state = stateCopy;
    this.emitChange();
  };

  public getState = (): ReadonlyArray<ModalDefinition> => {
    return this.state;
  };

  public subscribe = (subscriber: Subscriber) => {
    if (this.subscribers.indexOf(subscriber) < 0) {
      this.subscribers.push(subscriber);
      subscriber(this.state);
    }

    const unsubscribe = () => {
      const index = this.subscribers.indexOf(subscriber);

      if (index >= 0) {
        this.subscribers.splice(index, 1);
      }
    };

    return unsubscribe;
  };

  private emitChange() {
    this.subscribers.forEach(subscriber => {
      subscriber(this.state);
    });
  }
}

const modalStore = new ModalStore();

export default modalStore;
