import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './app';
import { FormProvider } from './create-form';
import formStore from './forms/store';
import ModalRenderer from './modal/renderer';
import { RedaxProvider } from './redax';
import { History, Router } from './router';
import store from './store';
import ToastCloseButton from './toast-close-button';

const history = new History();

ReactDOM.render(
  <RedaxProvider value={store}>
    <FormProvider value={formStore}>
      <Router history={history}>
        <App />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          closeButton={<ToastCloseButton />}
        />
      </Router>
    </FormProvider>
    <ModalRenderer />
  </RedaxProvider>,
  document.getElementById('app')
);
