import * as React from 'react';

import modalStore from './store';

export const ModalRenderer = () => {
  const [modals, setModals] = React.useState(modalStore.getState());

  React.useEffect(() => {
    return modalStore.subscribe(newModals => {
      setModals(newModals);
    });
  }, []);

  return (
    <>
      {modals.map(({ id, element }) => (
        <div key={id} className="modal-overlay">
          {element}
        </div>
      ))}
    </>
  );
};

export default ModalRenderer;
